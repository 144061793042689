import './App.css';
import {TextField, InputAdornment, IconButton, Grow, CircularProgress} from '@mui/material';
import {useState, useRef} from "react";
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import {Transition} from 'react-transition-group';
import MailIcon from '@mui/icons-material/Mail';
import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
import {createUser} from "./graphql/mutations";
import {Amplify, API} from "aws-amplify";
import awsconfig from './aws-exports'

// configure amplify
Amplify.configure({
    ...awsconfig,
    Analytics: {
        disabled: true, // causes error because of dependency or additional config needed
    },
});


const WaviyText = ({text}) => {
    const letters = text.split('');

    return (
        <div className="waviy">
            {letters.map((letter, index) => (
                <span key={index} style={{'--i': index + 1}}>
          {letter}
        </span>
            ))}
        </div>
    );
};

const isValidEmail = (testStr) => {
    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(testStr)
}

const App = () => {
    const [email, setEmail] = useState(null);
    const [invalidEmail, setInvalidEmail] = useState(false);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [failMsg, setFailMsg] = useState(null);
    const [disabled, setDisabled] = useState(true);

    const mailRef = useRef(null);
    const checkRef = useRef(null);
    const progressRef = useRef(null);
    const clearRef = useRef(null);


    // animation of button
    const duration = 300; // Animation duration in milliseconds

    const defaultStyle = {
        transition: `opacity ${duration}ms ease-in-out`,
        opacity: 0,
        display: 'flex',
        alignItems: 'center'
    };

    const transitionStyles = {
        entering: { opacity: 1 },
        entered: { opacity: 1 },
    };

    // end animation settings

    const inputStyles = {
        backgroundColor: 'whitesmoke',
        outline: 0
    };

    const handleEmailChange = (event) => {
        setSuccess(false);
        setLoading(false);
        setInvalidEmail(false);
        setFailMsg(null);
        const val = event.target.value;
        if (!isValidEmail(val)) {
            setInvalidEmail(true);
            setDisabled(true);
        } else {
            setDisabled(false);
        }
        setEmail(val);
    }

    const handleClick  = async () => {
        if(loading) return;
        setLoading(true);
        setFailMsg(null);
        setSuccess(false);
        try {
            let input = {
                email: email
            }
            const result = await API.graphql({
                query: createUser,
                variables: {input}
            });
            if(!result.errors){
                setSuccess(true);
                setDisabled(true);
            }
        } catch (e) {
            setDisabled(true);
            if(e.errors?.[0]?.errorType === "DynamoDB:ConditionalCheckFailedException"){
                setFailMsg("This e-mail is already subscribed.")
            } else {
                setFailMsg("Sorry. Failed to subscribe. Please try again later")
            }
        }
        setLoading(false);
    }

    const handleKeyPress = async (event) => {
        if (!disabled && !loading && event.key === 'Enter') {
            await handleClick()
        }
    }

    return (
        <div className="container">
            <div className="heart-logo text_shadows">💕</div>
            <h1 className="main-heading"><WaviyText text="DME"></WaviyText></h1>
            <h2 className="sub-heading">Safe. Honest. Dating</h2>
            <div className="email-input-container">
                <TextField
                    id="email-input"
                    autoFocus
                    onKeyDown={handleKeyPress}
                    helperText={failMsg}
                    FormHelperTextProps={{style:{color:'red'}}}
                    InputProps={{
                        style: inputStyles,
                        autoComplete: "email",
                        type: "email",
                        startAdornment: (
                            <InputAdornment position="start">
                                <AlternateEmailIcon/>
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton color="primary" onClick={handleClick}
                                            disabled={disabled}>
                                    <Transition
                                        nodeRef={mailRef}
                                        in={!failMsg && !loading && !success}
                                        timeout={50}
                                        mountOnEnter
                                        unmountOnExit
                                    >
                                        {(state) => (
                                            <div ref={mailRef} style={{ ...defaultStyle, ...transitionStyles[state] }}>
                                                {state === 'entered' && <MailIcon />}
                                            </div>
                                        )}
                                    </Transition>

                                    <Transition
                                        nodeRef={progressRef}
                                        in={loading}
                                        timeout={50}
                                        mountOnEnter
                                        unmountOnExit
                                    >
                                        {(state) => (
                                            <div ref={progressRef} style={{ ...defaultStyle, ...transitionStyles[state] }}>
                                                <CircularProgress color="primary" size={24} />
                                            </div>
                                        )}
                                    </Transition>

                                    <Transition
                                        nodeRef={checkRef}
                                        in={success}
                                        timeout={duration}
                                        mountOnEnter
                                        unmountOnExit
                                    >
                                        {(state) => (
                                            <div ref={checkRef} style={{ ...defaultStyle, ...transitionStyles[state] }}>
                                                {state === 'entered' && (
                                                    <Grow in={state === 'entered'}>
                                                        <CheckIcon style={{ color: 'green' }} />
                                                    </Grow>
                                                )}
                                            </div>
                                        )}
                                    </Transition>
                                    <Transition
                                        nodeRef={clearRef}
                                        in={!!failMsg}
                                        timeout={duration}
                                        mountOnEnter
                                        unmountOnExit
                                    >
                                        {(state) => (
                                            <div ref={clearRef} style={{ ...defaultStyle, ...transitionStyles[state] }}>
                                                {state === 'entered' && (
                                                    <Grow in={state === 'entered'}>
                                                        <ClearIcon style={{ color: 'red' }} />
                                                    </Grow>
                                                )}
                                            </div>
                                        )}
                                    </Transition>
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                    placeholder="Join the waiting list..."
                    icon="mail"
                    variant="outlined"
                    onChange={handleEmailChange}
                    error={invalidEmail}
                    fullWidth
                />
            </div>

        </div>
    );
};

export default App;
